import { RouteComponentProps } from '@reach/router';
import { Spin } from 'antd';
import React, { useEffect } from 'react';

interface Props extends RouteComponentProps {
  default?: boolean
}

const RedirectView:React.FC<Props> = () => {
  useEffect(() => {
    window.parent.postMessage({
      redirectRoute: '/learner/',
    }, '*');
  }, []);

  return <Spin size="default" style={{ height: '100vh' }} />;
};

export default RedirectView;
