import React from 'react';
import { Router } from '@reach/router';
import RedirectView from '../../components/Learner/RedirectView';

const redirect:React.FC = () => (
  <Router basepath="/learner/redirect">
    <RedirectView default />
  </Router>
);

export default redirect;
